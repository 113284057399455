export const PHONE_COUNTRY_CODE_IN: string = '91';
export const COUNTRY_CODE_IN: string = 'IN';
export const LANGUAGE_CODE_EN: string = 'en';

export const COMMON_HEADERS = {
  defaultLanguageCode: LANGUAGE_CODE_EN,
  defaultCountryCode: COUNTRY_CODE_IN
};

export const OTP_LOCALSTORAGE_KEY: string = 'timestamp';

export const RegEx = {
  ValidPhoneNumber: /^[0-9]{10}$/,
  OnlyDigits: /^[0-9]*$/,
  PhoneNumber: /^[0-9]{0,10}$/,
  TextwithSpaces: /^[a-zA-Z ]*$/,
  Alphnumeric: /^[a-zA-Z0-9- ]*$/
};

export interface IcountryCodeItem {
  name: string
  phoneCode: string
  countryCode: string
  langCode: string
}

export const countryList: IcountryCodeItem[] = [
  {
    name: 'India',
    phoneCode: '91',
    countryCode: 'IN',
    langCode: 'en'
  },
  {
    name: 'Pakistan',
    phoneCode: '92',
    countryCode: 'PK',
    langCode: 'en'
  },
  {
    name: 'Indonesia',
    phoneCode: '62',
    countryCode: 'ID',
    langCode: 'id'
  },
  {
    name: 'Bangladesh',
    phoneCode: '880',
    countryCode: 'BD',
    langCode: 'en'
  },
  {
    name: 'Thailand',
    phoneCode: '66',
    countryCode: 'TH',
    langCode: 'th'
  },
  {
    name: 'Malaysia',
    phoneCode: '60',
    countryCode: 'MY',
    langCode: 'ms'
  },
  {
    name: 'Vietnam',
    phoneCode: '84',
    countryCode: 'VN',
    langCode: 'en'
  },
  {
    name: 'Philippines',
    phoneCode: '63',
    countryCode: 'PH',
    langCode: 'en'
  },
  {
    name: 'Korea',
    phoneCode: '82',
    countryCode: 'KR',
    langCode: 'en'
  },
  {
    name: 'Zambia',
    phoneCode: '260',
    countryCode: 'ZM',
    langCode: 'en'
  }
];
